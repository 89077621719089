.siteinfo {
	@include media('<md') {
		padding: 10px 0 0;

		border-top: 1px solid #296339;
		border-left: 0;
	}

	padding-left: 18px;

	color: $green;
	border-left: 2px solid #296339;
}
