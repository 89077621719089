.objects {
	@include margin-top(40px);
	@include margin-bottom(40px);

	&__list {
		display: flex;

		margin: -40px -10px 0;

		flex-wrap: wrap;
	}

	&__item {
		@include media('<sm') {
			width: calc(50% - 20px);
		}
		@include media('<xs') {
			width: calc(100% - 20px);
		}

		width: calc(33.3333% - 20px);
		margin: 40px 10px 0;
	}

	&__more {
		font-size: 18px;
		line-height: 1.45;

		margin-top: 40px;

		text-align: right;
	}

	&__more-link {
		text-decoration: none;
	}

	&__more-text {
		text-decoration: underline;

		body:not(.mobile) .objects__more-link:hover & {
			text-decoration: none;
		}
	}

	&__more-icon {
		display: inline-block;

		width: 16px;
		height: 26px;
		margin-left: 4px;

		transition: fill $t;
		vertical-align: top;

		fill: $light-green;

		body:not(.mobile) .objects__more-link:hover & {
			fill: $green;
		}
	}
}
