.social {
	@include margin-top(40px);
	@include margin-bottom(40px);

	&__list {
		display: flex;

		margin: -20px -10px 0;

		flex-wrap: wrap;
	}

	&__item {
		width: 150px;
		margin: 20px 10px 0;
	}

	&__link {
		display: block;
	}

	&__image {
		display: block;

		max-width: 100%;
		height: auto;
	}
}
