.crumbs {
	font-size: 14px;

	display: flex;

	margin-bottom: 20px;

	flex-wrap: wrap;

	&__item {
		&:nth-last-child(n+2)::after {
			margin: 0 6px;

			content: '\002F';
		}
	}

	&__link {
		color: $dark-grey;
	}
}
