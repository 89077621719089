.popup {
	position: fixed;
	z-index: 100;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	display: none;
	visibility: hidden;
	overflow-y: auto;

	transition: opacity $t, visibility $t;

	opacity: 0;

	&::before {
		display: inline-block;

		height: 100%;

		content: '';
		vertical-align: middle;
	}

	&__inner {
		display: inline-block;

		width: 100%;
		padding: 10px;

		vertical-align: middle;
	}

	&__body {
		max-width: 540px;
		margin: 0 auto;
		padding: 29px;

		border: 1px solid #d0d0cc;
		background: white;
	}

	&__close {
		position: relative;

		float: right;

		width: 30px;
		height: 30px;
		margin-right: -9px;
		padding: 0;

		cursor: pointer;

		border: 0;
		outline: none;
		background: none;

		&::before,
		&::after {
			position: absolute;
			top: calc(50% - 1px);
			left: calc(50% - 8px);

			display: block;

			width: 16px;
			height: 2px;

			content: '';
			transition: background $t;

			background: $green;
		}

		&::before {
			transform: rotate(45deg);
		}

		&::after {
			transform: rotate(-45deg);
		}

		body:not(.mobile) &:hover {
			&:before,
			&:after {
				background: $light-green;
			}
		}
	}

	&__title {
		@include margin-bottom(20px);
		@include media('<sm') {
			font-size: 24px;
		}

		font-size: 30px;
	}

	&._active {
		display: block;
	}

	&._visible {
		visibility: visible;

		opacity: 1;
	}
}
