.btn {
	font-size: 18px;
	line-height: 1.25;

	position: relative;
	z-index: 1;

	display: inline-block;

	padding: 5px 20px;

	cursor: pointer;

	color: white;
	border: 0;
	border-radius: 0;
	outline: none;
	background: none;

	&::before {
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;

		display: block;

		width: 100%;
		height: 100%;

		content: '';
		transition: background $t;
		transform: skewX(-.1rad);

		background: #01b934;
	}

	body:not(.mobile) &:hover::before {
		background: $light-green;
	}
}
