.tel {
	@include media('<lg') {
		order: 1;
	}
	@include media('<1025px') {
		line-height: 1.34;

		width: auto;
		margin-top: 10px;
		margin-right: 20px;

		order: 0;
	}
	@include media('<xs') {
		margin: 10px 10px 0;

		text-align: center;
	}

	font-family: $signpainter;
	font-size: 24px;
	line-height: 1.67;

	width: 190px;

	&__link {
		text-decoration: none;

		color: white;
	}

	&__code {
		color: $lightest-green;
	}
}
