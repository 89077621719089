.categories {
	max-width: 1200px;
	margin: 40px auto;
	padding: 0 10px;

	&__title {
		@include media('<sm') {
			font-size: 24px;
		}

		font-size: 30px;
	}

	&__list {
		display: flex;

		margin: 0 -10px;

		flex-wrap: wrap;
	}

	&__item {
		@include media('<md') {
			width: calc(50% - 20px);

			justify-content: center;
		}
		@include media('<xs') {
			width: calc(100% - 20px);
		}

		display: flex;

		width: calc(33.3333% - 20px);
		margin: 40px 10px 0;

		&:nth-child(3n+1) {
			@include media('>=md') {
				justify-content: flex-start;
			}
		}

		&:nth-child(3n+2) {
			@include media('>=md') {
				justify-content: center;
			}
		}

		&:nth-child(3n+3) {
			@include media('>=md') {
				justify-content: flex-end;
			}
		}
	}

	&__link {
		position: relative;

		display: block;

		max-width: 305px;
		padding: 0 5px 5px 0;

		text-decoration: none;

		color: white;
	}

	&__image {
		display: block;

		max-width: 100%;
		height: auto;

		transition: box-shadow $t;

		box-shadow: 5px 5px 0 $lightest-green;

		body:not(.mobile) .categories__link:hover & {
			box-shadow: 5px 5px 0 $green;
		}
	}

	&__name {
		@include media('<sm') {
			font-size: 16px;
		}

		font-size: 18px;

		position: absolute;
		right: 0;
		bottom: 20px;

		max-width: 100%;
		padding: 5px 12px;

		text-decoration: underline;

		background: rgba(#464341, .75);

		body:not(.mobile) .categories__link:hover & {
			text-decoration: none;
		}
	}
}
