.description {
	@include margin-top(40px);
	@include margin-bottom(40px);

	&__group {
		@include margin-bottom(30px);
		@include media('<sm') {
			@include margin-bottom(24px);
		}
	}

	&__label {
		@include media('<sm') {
			font-size: 16px;
		}

		font-size: 18px;
	}

	&__value {
		@include media('<sm') {
			font-size: 24px;
		}

		font-size: 30px;
	}
}
