.benefits {
	background: #cdcecd url(../images/benefits-bg.jpg) repeat 50% 50%;

	&__inner {
		max-width: 1200px;
		margin: 0 auto;
		padding: 40px 10px;
	}

	&__title {
		@include media('<sm') {
			font-size: 24px;
		}

		font-size: 30px;
		font-weight: 900;
	}

	&__list {
		display: flex;

		margin: 0 -10px;

		align-items: center;
		flex-wrap: wrap;
	}

	&__item {
		@include media('<md') {
			width: calc(50% - 20px);
		}
		@include media('<xs') {
			width: calc(100% - 20px);
			margin-top: 20px;
		}

		display: flex;

		width: calc(33.3333% - 20px);
		margin: 30px 10px 0;

		align-items: center;
	}

	&__hero {
		width: 80px;
		padding-right: 10px;
	}

	&__text {
		@include media('<xs') {
			max-width: none;
		}

		font-size: 18px;

		width: calc(100% - 80px);
		max-width: 240px;
	}
}
