.gallery {
	@include margin-top(30px);
	@include margin-bottom(30px);
	@include media('<lg') {
		margin-right: 0;
		margin-left: 0;
	}

	position: relative;

	margin: 0 -10px;

	&__item {
		@include media('<lg') {
			width: 50%;
		}
		@include media('<md') {
			width: 33.3333%;
		}
		@include media('<sm') {
			width: 50%;
		}
		@include media('<xs') {
			width: 100%;
		}

		width: 33.3333%;
		padding: 0 10px;
	}

	&__prev {
		position: absolute;
		z-index: 1;
		top: calc(50% - 13px);
		left: 0;
	}

	&__next {
		position: absolute;
		z-index: 1;
		top: calc(50% - 13px);
		right: 0;
	}
}
