.works {
	max-width: 1200px;
	margin: 40px auto;
	padding: 0 10px;

	&__title {
		@include margin-bottom(30px);
		@include media('<sm') {
			font-size: 24px;
		}

		font-size: 30px;
	}

	&__slider {
		@include media('<lg') {
			margin: 0;
		}

		position: relative;

		margin: 0 -10px;
	}

	&__item {
		@include media('<md') {
			width: 33.3333%;
		}
		@include media('<sm') {
			width: 50%;
		}
		@include media('<xs') {
			width: 100%;
		}

		width: 25%;
		padding: 0 10px;
	}

	&__prev {
		position: absolute;
		z-index: 1;
		top: calc(50% - 13px);
		left: 0;
	}

	&__next {
		position: absolute;
		z-index: 1;
		top: calc(50% - 13px);
		right: 0;
	}
}
