.footer-nav {
	@include margin-bottom(20px);
	@include media('<md') {
		@include margin-bottom(10px);

		font-size: 16px;
	}

	font-size: 18px;

	&__list {
		@include reset-list;
		@include media('<md') {
			margin: 0 -10px;
		}

		display: flex;

		margin: 0 -15px;

		flex-wrap: wrap;
	}

	&__item {
		@include media('<md') {
			margin: 0 10px;
		}

		margin: 0 15px;
	}
}
