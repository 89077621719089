.object {
	max-width: 250px;
	margin: 0 auto;

	&__location {
		@include margin-bottom(10px);

		text-align: right;

		color: $green;

		&::before {
			display: inline-block;

			width: 11px;
			height: 17px;
			margin: 1px 5px 0 0;

			content: '';
			vertical-align: top;

			background: url(../images/location.svg) no-repeat 50% 50% / 11px 17px;
		}
	}

	&__hero {
		position: relative;

		display: block;
		overflow: hidden;

		max-width: 220px;
		margin: 0 15px 5px;

		transition: box-shadow $t;
		transform: skewX(-.1rad);

		outline: none;
		box-shadow: 5px 5px 0 $lightest-green;

		&::before {
			display: block;

			width: 100%;
			padding-top: 121%;

			content: '';
		}

		body:not(.mobile) &:hover {
			box-shadow: 5px 5px 0 $green;
		}
	}

	&__image {
		position: absolute;
		top: 0;
		left: 50%;

		width: auto;
		height: 100%;

		transform: skewX(.1rad) translateX(-50%);
	}
}
