.footer-tel {
	@include margin-bottom(20px);
	@include media('<md') {
		@include margin-bottom(10px);
	}

	font-family: $signpainter;
	font-size: 21px;
	line-height: 1.05;

	&__link {
		text-decoration: none;

		color: $green;
	}

	&__code {
		color: $lightest-green;
	}
}
