.callorder {
	@include media('<lg') {
		padding: 22px 0 5px;

		text-align: right;
	}
	@include media('<1025px') {
		width: auto;
		margin-top: 10px;
		padding: 0;

		text-align: left;
	}
	@include media('<xs') {
		margin: 10px 10px 0;

		text-align: center;
	}

	width: 220px;
}
