.footer {
	@include media('<md') {
		display: block;
	}

	display: flex;

	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	padding: 40px 0 20px;


	&__left {
		@include media('<lg') {
			width: 240px;
		}
		@include media('<md') {
			width: auto;
		}

		width: 320px;
		padding: 0 10px;
	}

	&__middle {
		@include media('<lg') {
			width: calc(100% - 440px);
		}
		@include media('<md') {
			width: auto;
			margin-top: 20px;
		}

		width: calc(100% - 520px);
		padding: 0 10px;
	}

	&__right {
		@include media('<md') {
			width: auto;
			margin-top: 20px;
		}

		width: 200px;
		padding: 0 10px;
	}
}
