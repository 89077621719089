.get-estimate {
	@include margin-top(20px);

	font-size: 18px;

	&__link {
		text-decoration: none;

		border-bottom: 1px dotted;
	}
}
