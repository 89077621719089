.content {
	display: flex;

	max-width: 1200px;
	margin: 0 auto;
	padding: 40px 10px;

	flex-wrap: wrap;
	justify-content: space-between;

	&__main {
		@include media('>=md') {
			max-width: 820px;
		}
		@include media('<md') {
			width: 100%;
			padding: 0;
		}

		width: calc(100% - 320px);
		padding-right: 20px;
	}

	&__sidebar {
		@include media('<md') {
			width: 100%;
			margin-top: 40px;
		}

		width: 320px;
	}
}
