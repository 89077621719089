.nav {
	@include media('<lg') {
		width: calc(100% - 190px);

		order: 1;
	}
	@include media('<1025px') {
		position: absolute;
		top: 25px;
		left: 10px;

		width: 32px;
		height: 32px;

		order: 0;
	}

	width: calc(100% - 410px);

	&__open-button {
		@include media('>=1025px') {
			display: none;
		}

		position: relative;
		z-index: 1;

		display: block;

		width: 32px;
		height: 32px;
		padding: 0;

		cursor: pointer;

		border: 0;
		outline: none;
		background: none;

		&::before,
		&::after,
		& > i {
			position: absolute;
			left: calc(50% - 12px);

			display: block;

			width: 24px;
			height: 3px;

			content: '';
			transform: skewX(-.1rad);

			background: white;
		}

		&::before {
			top: calc(50% - 8px);
		}

		&::after {
			top: calc(50% + 6px);
		}

		& > i {
			top: calc(50% - 1px);
		}
	}

	&__inner {
		@include media('<1025px') {
			position: fixed;
			z-index: 100;
			top: 0;
			left: 0;

			width: 100%;
			max-width: 320px;
			height: 100%;
			padding: 50px 10px 10px;

			transition: transform $t;
			transform: translateX(-100%);

			background: $green;
		}

		.nav._open & {
			transform: translateX(0);
		}
	}

	&__close-button {
		@include media('>=1025px') {
			display: none;
		}

		position: absolute;
		top: 9px;
		right: 10px;

		display: block;

		width: 32px;
		height: 32px;
		padding: 0;

		cursor: pointer;

		border: 0;
		outline: none;
		background: none;

		&::before,
		&::after {
			position: absolute;
			top: calc(50% - 1px);
			left: calc(50% - 8px);

			display: block;

			width: 16px;
			height: 2px;

			content: '';
			transition: background $t;

			background: white;
		}

		&::before {
			transform: rotate(45deg);
		}

		&::after {
			transform: rotate(-45deg);
		}
	}

	&__list {
		@include reset-list;
		@include media('<1025px') {
			display: block;
		}

		display: flex;
	}

	&__item {
		@include media('<1025px') {
			border-bottom: 1px solid rgba(white, .15);
		}

		position: relative;

		transition: background $t;

		body:not(.mobile) &:hover,
		&._open {
			@include media('>=1025px') {
				background: #72706e;
			}
		}
	}

	&__link {
		@include media('<1025px') {
			display: block;

			width: 100%;
			padding: 8px 0;
		}

		font-size: 18px;
		line-height: 1.34;

		position: relative;

		display: block;

		padding: 8px 15px;

		text-decoration: none;

		color: white;

		.nav__toggle + & {
			@include media('<1025px') {
				width: calc(100% - 32px);
			}

			padding-right: 26px;
		}
	}

	&__toggle {
		@include media('<1025px') {
			top: 4px;
			right: 0;

			width: 32px;
			height: 32px;

			color: $green;
			background: white;
		}

		position: absolute;
		top: calc(50% - 3px);
		right: 15px;

		display: block;

		width: 6px;
		height: 6px;
		padding: 0;

		cursor: pointer;

		color: $light-green;
		border: 0;
		outline: none;
		background: none;
	}

	&__toggle-icon {
		@include media('<1025px') {
			position: absolute;
			top: calc(50% - 4px);
			left: calc(50% - 4px);

			width: 8px;
			height: 8px;
		}

		display: block;

		width: 6px;
		height: 6px;

		transition: transform $t;

		fill: currentColor;

		.nav__item._open & {
			@include media('<1025px') {
				transform: rotate(180deg);
			}
		}
	}

	&__submenu {
		@include reset-list;
		@include media('>=1025px') {
			visibility: hidden;

			transition: opacity $t, visibility $t;

			opacity: 0;

			will-change: opacity, visibility;
		}
		@include media('<1025px') {
			font-size: 16px;

			position: static;
			top: auto;
			left: auto;

			overflow: hidden;

			padding: 10;

			transition: height $t;

			background: rgba(white, .05);
			box-shadow: none;

			will-change: height;
		}

		font-size: 18px;
		line-height: 1.25;

		position: absolute;
		top: 100%;
		left: 0;

		display: none;

		padding: 15px;

		background: white;
		box-shadow: 0 0 9px rgba(black, .67);

		&._visible {
			@include media('>=1025px') {
				visibility: visible;

				opacity: 1;
			}
		}

		.nav__item._open & {
			display: block;
		}

		.nav__item._open &:not(._visible) {
			@include media('<1025px') {
				position: absolute;

				width: 100%;
			}
		}
	}

	&__submenu-item {
		@include margin-bottom(14px);
		@include media('<1025px') {
			@include margin-bottom(10px);
		}

		white-space: nowrap;
	}

	&__submenu-link {
		@include media('<1025px') {
			color: white;
		}

		color: $dark-grey;
	}
}
