.logo {
	@include media('<1025px') {
		left: 62px;
	}
	@include media('<xs') {
		position: static;

		width: 100%;
		margin-bottom: 10px;

		text-align: center;
	}

	position: absolute;
	top: 6px;
	left: 31px;

	&__link {
		@include media('<1025px') {
			width: 80px;
		}

		display: inline-block;

		vertical-align: top;
	}

	&__image {
		display: block;

		max-width: 100%;
		height: auto;
	}

	.header._fixed & {
		@include media('>=lg') {
			top: 1px;

			width: 80px;

			transition: all $t;
		}
	}
}
