.contacts {
	@include margin-top(30px);
	@include margin-bottom(30px);
	@include media('<lg') {
		display: block;
	}

	display: flex;

	align-items: flex-start;
	flex-wrap: wrap;

	&__info {
		@include media('<lg') {
			width: auto;
			padding-right: 0;
		}

		width: 330px;
		padding-right: 20px;
	}

	&__map {
		@include media('<lg') {
			width: auto;
			margin-top: 20px;
		}

		width: calc(100% - 330px);
		height: 300px;
		max-height: 90vh;
	}
}
