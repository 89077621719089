*,
:before,
:after {
	box-sizing: border-box;
}

html {
	font-family: $sourcesanspro;
	font-size: 16px;
	line-height: 1.25;

	display: flex;

	min-height: 100%;

	color: $dark-grey;
	background: white;

	&.fixed {
		overflow-y: hidden;

		height: 100%;
	}

	&.has-scrollbar {
		overflow-y: scroll;
	}
}

body {
	display: flex;
	flex-direction: column;

	width: 100%;
	min-width: 320px;

	&.mobile {
		cursor: pointer;
	}

	html.fixed & {
		overflow: hidden;
	}
}

a {
	color: $green;

	body:not(.mobile) &:hover {
		text-decoration: none;
	}
}

h1 {
	@include margin-bottom(.5em);
	@include media('<sm') {
		font-size: 24px;
	}

	font-size: 30px;
	font-weight: normal;

	margin: 0;

	b {
		font-weight: 600;
	}
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}
