.workhours {
	@include media('<lg') {
		width: calc(100% - 220px);
	}
	@include media('<1025px') {
		line-height: 1.43;

		width: 100%;
		padding: 0;
	}
	@include media('<xs') {
		text-align: center;
	}

	font-size: 14px;
	line-height: 32px;

	width: 100%;
	padding: 24px 0 4px;

	.header._fixed & {
		display: none;
	}
}
