// Fonts
$sourcesanspro: 'Source Sans Pro', sans-serif;
$signpainter: 'SignPainter', cursive;

// Colors
$green: #087a1d;
$light-green: #54c172;
$lightest-green: #7bf69c;
$dark-grey: #333;

// Transition
$t: .2s ease;

// Breakpoints
$breakpoints: (
	'xs': 568px,
	'sm': 768px,
	'md': 1024px,
	'lg': 1200px
);
