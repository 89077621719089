.services {
	@include margin-top(40px);
	@include margin-bottom(40px);

	&__title {
		@include margin-bottom(10px);
		@include media('<sm') {
			font-size: 24px;
		}

		font-size: 30px;
	}

	&__slider {
		@include media('<md') {
			margin: 0 -10px;
		}
	}

	&__item {
		@include media('<md') {
			width: 50%;
			padding: 0 10px;
		}
		@include media('<xs') {
			width: 100%;
		}

		width: 100%;
	}

	&__hero {
		max-width: 305px;
		padding-right: 5px;
	}

	&__image {
		display: block;

		max-width: 100%;
		height: auto;

		box-shadow: 5px 5px 0 $lightest-green;
	}

	&__caption {
		@include margin-top(20px);

		font-size: 18px;

		margin-left: 20px;
	}

	&__text {
		@include margin-top(10px);

		margin-left: 20px;
	}

	&__pagination {
		@include media('<md') {
			margin: 20px 10px 0;
		}

		display: flex;

		width: auto !important;
		margin-top: 20px;
		padding-right: 2px;

		justify-content: flex-end;
	}

	&__bullet {
		display: block;

		width: 14px;
		height: 14px;
		margin-left: 8px;

		cursor: pointer;
		transform: skewX(-.1rad);

		background: $green;

		&._active {
			background: #4e4e4e;
		}
	}
}
