.arrow {
	width: 16px;
	height: 26px;
	padding: 0;

	cursor: pointer;
	transition: color $t;

	color: $light-green;
	border: 0;
	outline: none;
	background: none;

	&__icon {
		display: block;

		width: 16px;
		height: 26px;

		fill: currentColor;
	}

	body:not(.mobile) &:not(.swiper-button-disabled):hover {
		color: $lightest-green;
	}

	&.swiper-button-disabled {
		cursor: default;

		opacity: .5;
	}
}
