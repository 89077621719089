.promo {
	position: relative;

	&__body {
		@include media('<md') {
			width: 500px;
		}
		@include media('<xs') {
			top: auto;
			bottom: 0;
			left: 0;

			width: 100%;

			transform: none;

			background: rgba(#464341, .75);
		}

		position: absolute;
		z-index: 2;
		top: 50%;
		left: 50%;

		width: 670px;
		padding: 10px;

		transform: translate(-50%, -50%);
		text-align: center;

		color: white;

		&::before {
			@include media('<xs') {
				display: none;
			}

			position: absolute;
			z-index: -1;
			top: 0;
			left: 0;

			display: block;

			width: 100%;
			height: 100%;

			content: '';
			transform: skewX(-.1rad);

			background: rgba(#464341, .75);
		}
	}

	&__caption {
		@include media('<md') {
			font-size: 40px;
		}
		@include media('<xs') {
			font-size: 24px;
		}

		font-size: 60px;
		line-height: 1;
	}

	&__text {
		@include media('<xs') {
			font-size: 16px;
		}

		font-size: 18px;

		margin-top: 5px;
	}

	&__link {
		color: white;
	}

	&__item {
		@include media('<lg') {
			height: 480px;
		}
		@include media('<md') {
			height: 410px;
		}
		@include media('<sm') {
			height: 310px;
		}
		@include media('<xs') {
			height: 230px;
		}

		position: relative;

		height: 672px;

		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: cover;
	}

	&__pagination {
		@include media('<lg') {
			left: 10px !important;
		}
		@include media('<xs') {
			top: 20px;
			right: 10px !important;
			left: auto !important;
		}

		position: absolute;
		z-index: 1;
		bottom: 20px !important;
		left: calc(50% - 590px) !important;

		display: flex;

		width: auto !important;
	}

	&__bullet {
		display: block;

		width: 50px;
		height: 14px;
		margin-right: 10px;

		cursor: pointer;
		transform: skewX(-.1rad);

		background: $green;

		&._active {
			background: #4e4e4e;
		}
	}
}
