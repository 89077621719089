.a-nav {
	@include margin-top(40px);
	@include margin-bottom(40px);

	font-size: 18px;
	line-height: 1.34;

	padding: 20px;

	color: white;
	background: #72706e;

	&__list {
		@include reset-list;
	}

	&__item {
		@include margin-bottom(12px);
	}

	&__link {
		color: white;
	}
}
