.header {
	@include media('>=lg') {
		height: 100px;
	}
	@include media('>=1025px') {
		z-index: 5;
	}
	@include media('<1025px') {
		color: white;
		background: $green;
	}

	&__wrapper {
		.header._fixed & {
			@include media('>=lg') {
				position: fixed;
				z-index: 10;
				top: 0;
				left: 0;

				width: 100%;

				background: $green;
			}
		}
	}

	&__inner {
		@include media('<1025px') {
			padding: 10px 10px 10px 160px;
		}
		@include media('<xs') {
			padding: 10px;

			justify-content: center;
		}

		position: relative;

		display: flex;

		max-width: 1200px;
		margin: 0 auto;
		padding: 0 10px 0 185px;

		align-items: center;
		flex-wrap: wrap;
	}

	&._type_index {
		@include media('>=1025px') {
			position: absolute;
			top: 0;
			left: 0;

			width: 100%;

			color: white;
			background: linear-gradient(to top, $green, $green 40px, rgba(#1c592d, .66) 40px, rgba(#1c592d, .66));
		}

		position: relative;
	}
	&._type_inside {
		@include media('>=1025px') {
			background: linear-gradient(to top, $green, $green 40px, white 40px, white);
		}
	}
}
