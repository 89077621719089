.form {
	&__columns {
		@include margin-bottom(24px);
		@include media('<xs') {
			display: block;

			margin: 0;
		}

		display: flex;

		margin: 0 -10px;

		flex-wrap: wrap;
	}

	&__column {
		@include media('<xs') {
			@include margin-bottom(24px);

			width: auto;
			margin: 0;
		}

		width: calc(50% - 20px);
		margin: 0 10px;
	}

	&__group {
		@include margin-bottom(24px);
	}

	&__entry {
		font-size: 16px;
		line-height: 1.25;

		display: block;

		width: 100%;
		height: 32px;
		padding: 5px 9px;

		color: $dark-grey;
		border: 1px solid #72706e;
		outline: none;
		background: white;

		&::placeholder {
			opacity: 1;
			color: #929292;
		}

		&._textarea {
			height: 72px;
		}
	}
}
